<template>
  <span 
    class="inline-block font-mono font-medium pink-bg-light leading-none rounded-sm px-2 cursor-pointer"
    @click="showVideo(time)"
  >
    {{ formatTimeText(formatSeconds(time)) }}
  </span>
</template>

<script>
  import { mapActions } from 'vuex'
  import { formatTimeText, formatSeconds } from '@/helpers/formats'

  export default {
    props: ['video', 'time'],
    data(){
      return{
        formatTimeText: formatTimeText,
        formatSeconds: formatSeconds
        // localTimeText: ''
      }
    },
    methods: {
      ...mapActions({
        activateVideo: 'search/activateVideo',
      }),
      showVideo(time){
        if(this.video){
          this.activateVideo({
            video_id: '',
            timestamp: '',
            show: false
          })
          setTimeout(() => {
            this.activateVideo({
              video_id: this.video.video_id,
              timestamp: Number(time),
              show: true
            })
          }, 0);
        }
      },
    }
  }
</script>

<style lang="scss" scoped>

</style>